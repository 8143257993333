import '../logger'
import * as Sentry from '@sentry/node'
import { Grommet } from 'grommet'
import { grommet } from 'grommet/themes'
import { NextPage } from 'next'
import Head from 'next/head'
import React from 'react'

import { ModalProvider } from '~/components/modals/context'

import '../styles.css'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
  release: process.env.SENTRY_RELEASE,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = { Component: NextPage; pageProps: any }

const RootComponent: NextPage<Props> = ({ Component, pageProps }) => (
  <Grommet theme={grommet} themeMode="dark">
    <Head>
      <title>Watch Party</title>
      <meta name="description" content="Watch videos live and chat with others" />
    </Head>
    <ModalProvider>
      <Component {...pageProps} />
    </ModalProvider>
  </Grommet>
)

export default RootComponent
